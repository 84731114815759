import React from 'react';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';
import img5 from '../images/img5.png';

const data = [
    {
        id: 1,
        title: 'TO DO APP',
        img: img1,
        info: 'Not the usual to-do app. In it the user can insert, mark tasks for completed, deleted and even edit them.',
        link: 'https://todoappsandip.netlify.app/',
        source: 'https://github.com/sandipkarmokar/to-do-app',
    },
    {
        id: 2,
        title: 'WEATHER APP',
        img: img2,
        info: 'A weather app which not only displays the current temperature but alson pressure, humidity, wind and sunrise/sunset.',
        link: 'https://weatherappsandip.netlify.app/',
        source: 'https://github.com/sandipkarmokar/weather-app',
    },
    {
        id: 3,
        title: 'RECIPE APP',
        img: img3,
        info: 'Recipe App build using the Forkify Api. In it a user can search by ingredient, keyword etc.',
        link: 'https://recipeappsandip.netlify.app/',
        source: 'https://github.com/sandipkarmokar/recipeApp',
    },
    {
        id: 4,
        title: 'Design Insta Page',
        img: img4,
        info: 'This is where I post my UI designs',
        link: 'https://www.instagram.com/designs_by_sandip/',
    },
    {
        id: 5,
        title: 'Github Profile',
        img: img5,
        info: 'Links to other projects done by me.',
        link: 'https://github.com/sandipkarmokar',
    },
];

const Projects = () => {
    return (
        <div id="project">
            <div className="project-container">
                <h2>PROJECTS</h2>
                <div className="project-items">
                    {data.map((el) => {
                        return (
                            <div className="item">
                                <div className="item-img">
                                    <img src={el.img} alt={el.title} />
                                </div>
                                <div className="item-info">
                                    <p className="title">{el.title}</p>
                                    <p className="info">{el.info}</p>
                                    <div className="links">
                                        <a
                                            href={el.link}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            visit
                                        </a>
                                        {el.source && (
                                            <a
                                                href={el.source}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                source
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Projects;
