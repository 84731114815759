import React from 'react';
import html from '../images/html.png';
import css from '../images/css.png';
import js from '../images/js.png';
import react from '../images/react.png';
import uiux from '../images/uiux.png';

const data = [
    { id: 1, title: 'HTML', img: html },
    { id: 2, title: 'CSS', img: css },
    { id: 3, title: 'JAVASCRIPT', img: js },
    { id: 4, title: 'REACT', img: react },
    { id: 5, title: 'UI/UX designing', img: uiux },
];

const About = () => {
    return (
        <div id="about">
            <h2>ABOUT ME</h2>
            <div className="about-info">
                <p>
                    Hi, I am Sandip a self taught Frontend Developer and UI/UX
                    designer. I am currently a Computer Science undergrad. I
                    will help you design and build websites which looks stunning
                    and equally functional.{' '}
                </p>
            </div>
            <h3>SKILLS</h3>
            <div className="skills-grid">
                {data.map((el) => {
                    return (
                        <div className="skill-item" key={el.id}>
                            <div className="image">
                                <img src={el.img} alt={el.title} />
                            </div>
                            <p>{el.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default About;
