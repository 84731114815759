import React from 'react';

const Contact = () => {
    return (
        <div id="contact">
            <div className="contact-container">
                <h3>CONTACT ME</h3>
                <p>Lets get in touch and build your website</p>
                <div className="contact-details">
                    <div className="contact-info">
                        <p className="small">Phone + Whatsapp</p>
                        <p className="main">(+91) 9365925866</p>
                        <p className="small">Email</p>
                        <p className="main">skarmokar987@gmail.com</p>
                    </div>
                    {/* <form name="contact" method="POST" data-netlify="true">
                        <input
                            type="hidden"
                            name="form-name"
                            value="name_of_my_form"
                        />

                        <p>
                            <label>
                                Your Name: <input type="text" name="name" />
                            </label>
                        </p>
                        <p>
                            <label>
                                Your Email: <input type="email" name="email" />
                            </label>
                        </p>
                        <p>
                            <label>
                                Message: <textarea name="message"></textarea>
                            </label>
                        </p>
                        <p>
                            <button type="submit">Send</button>
                        </p>
                    </form> */}
                    <form action="submit" className="contact-form">
                        <input type="text" placeholder="First Name" />
                        <input type="text" placeholder="Email" />
                        <textarea
                            name=""
                            id=""
                            rows="10"
                            placeholder="Message"
                        ></textarea>
                        <button className="btn">SUBMIT</button>
                    </form>
                </div>
                <p className="footer">
                    2022 Sandip Karmokar. All rights reserved
                </p>
            </div>
        </div>
    );
};

export default Contact;
