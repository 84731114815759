import './App.css';
import Home from './components/home';
import About from './components/about';
import Projects from './components/projects';
import Contact from './components/contacts';

function App() {
    return (
        <>
            <div className="app-container">
                <Home></Home>
                <About></About>
            </div>
            <Projects></Projects>
            <Contact></Contact>
        </>
    );
}

export default App;
