import React, { useState } from 'react';
import menuIcon from '../images/menu.png';
import cross from '../images/x.png';
import heroImage from '../images/Saly-10.png';
import hand from '../images/hand.png';

const Home = () => {
    const [toggle, setToggle] = useState(false);
    return (
        <div className="home">
            <div className="nav-bar">
                <div className="logo">SK</div>
                <div className="nav-icon">
                    <img
                        src={menuIcon}
                        onClick={() => setToggle(!toggle)}
                        alt="#"
                    />
                </div>
                <div className={toggle ? 'nav-items show' : 'nav-items'}>
                    <img
                        src={cross}
                        onClick={() => setToggle(!toggle)}
                        alt="X"
                    />
                    <a href="#project">My Work</a>
                    <a href="#about">About Me</a>
                    <a href="#contact">Contact Me</a>
                </div>
                <div className="nav-items-full">
                    <a href="#project">My Work</a>
                    <a href="#about">About Me</a>
                    <a href="#contact">Contact Me</a>
                </div>
            </div>
            <div className="hero">
                <div className="hero-image">
                    <img src={heroImage} alt="" />
                </div>
                <div className="hero-text">
                    <p className="intro">
                        Hi there its,
                        <span>
                            <img src={hand} alt="" />
                        </span>
                    </p>
                    <p className="sub-intro">
                        SANDIP, I will help you design and build
                    </p>
                    <p className="bold">
                        The <span className="main">PERFECT WEBSITE</span> FOR
                        YOU
                    </p>
                </div>
            </div>
            <div className="buttons">
                <a href="#about">
                    <button className="cta-primary">Know More</button>
                </a>
                <a href="#contact">
                    <button className="cta-secondary">Contact Me</button>
                </a>
            </div>
        </div>
    );
};

export default Home;
